import {IonButton, IonCheckbox, IonCol, IonIcon, IonInput, IonRow, IonSelect, IonSelectOption, IonToggle, useIonAlert} from "@ionic/react";
import classNames from "classnames";
import {chevronBack, chevronDown, chevronForward, chevronUp, createOutline, trashOutline} from "ionicons/icons";
import moment from "moment";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {Filter, User, UserShort, ModuleShort} from "../../Api";
import {trainerApi, dataApi} from "../../index";
import {AppState} from "../../store";
import {clearUserAction} from "../../store/auth/actions";
import {setFilter, setReloadList} from "../../store/data/actions";
import {setErrorAction} from "../../store/error/actions";
import {setShowLoadingAction} from "../../store/system/actions";
import EvaluationChanger from "../EvaluationChanger/EvaluationChanger";
import ModuleChanger from "../ModuleChanger/ModuleChanger";
import NameChanger from "../NameChanger/NameChanger";
import ParticipantsSearchBar from "../ParticipantsSearchBar/ParticipantsSearchBar";
import style from "./ParticipantsList.module.scss";


const ParticipantsList: React.FC<any> = (props) => {

    const dispatch = useDispatch();
    const history = useHistory()
    const {t} = useTranslation();
    const language = useSelector<AppState, string>(state => state.system.language);
    const trainer = useSelector<AppState, User>(state => state.auth.user);
    const [userList, setUserList] = useState<Array<UserShort>>([]);
    const [checkList, setCheckList] = useState<Array<boolean>>([]);
    const [checkedAll, setCheckedAll] = useState<boolean>(false);
    const [checkAllFlag, setCheckAllFlag] = useState<boolean>(false);
    const [preventChange, setPreventChange] = useState<boolean>(false);
    const [isIndeterminate, setIsIndeterminate] = useState<boolean>(false);
    const filter = useSelector<AppState, Filter>(state => state.data.filter);
    const [maxPage, setMaxPage] = useState(1);
    const [pageList, setPageList] = useState<Array<number | string>>([]);
    const [showGroupEditList, setShowGroupEditList] = useState<Array<boolean>>([]);
    const [showValuesEditList, setShowValuesEditList] = useState<Array<boolean>>([]);
    const [showModulesEditList, setShowModulesEditList] = useState<Array<boolean>>([]);
    const [showNameEditList, setShowNameEditList] = useState<Array<boolean>>([]);
    const [present] = useIonAlert();
    const editGroupInputs = useRef<any>([]);
    const reloadList = useSelector<AppState, boolean>(state => state.data.reloadList);
    const showError = useSelector<AppState, boolean>(state => state.error.isOpen);
    const [modules, setModules] = useState<Array<ModuleShort>>();
    const [sorted, setSorted] = useState<Array<UserShort>>([]);

    useEffect(() => {
        const loadModules = async () => {
            try {
                dispatch(setShowLoadingAction(true));
                const response = await dataApi.getModulesShort(language, trainer.id as number);
                setModules(response.data);
                dispatch(setShowLoadingAction(false));
            } catch (e) {
                if (e.response?.status === 401) {
                    dispatch(clearUserAction());
                    history.replace("/login");
                }
                dispatch(setShowLoadingAction(false));
                dispatch(setErrorAction(e));
            }
        }
        loadModules();

    }, [language])

    useEffect(() => {
        const loadItems = async function () {
            dispatch(setShowLoadingAction(true));
            try {
                setPreventChange(true);
                setCheckAllFlag(true);
                const data = await trainerApi.getTrainerUserList(trainer.id as number, language, filter);

                // setup arrays
                if (data.data.userList) {

                    let sorted = data.data.userList.sort((a,b) => {
                        return new Date(moment(a.createdAt).toDate()).getTime() - new Date(moment(b.createdAt).toDate()).getTime();
                    })
                    setSorted(sorted);

                    setUserList(data.data.userList.reverse());
                    let tmp = [];
                    for (let i = 0; i < data.data.userList.length; i++) {
                        tmp.push(false);
                    }
                    setCheckList(tmp);
                    setShowGroupEditList(tmp);
                    setShowValuesEditList(tmp);
                    setShowModulesEditList(tmp);
                    setShowNameEditList(tmp);
                    setCheckedAll(false);
                }


                // setup pagiantion
                data.data.maxPage = data.data.maxPage ? data.data.maxPage : 1;
                setMaxPage(data.data.maxPage);
                const arr = [];
                if (filter.page && data.data.maxPage > 7) {
                    arr[0] = 1;
                    arr[1] = filter.page > 4 ? "..." : 2;
                    arr[2] = filter.page < 5 ? 3 : data.data.maxPage - filter.page > 3 ? filter.page - 2 : data.data.maxPage - 6;
                    arr[3] = filter.page < 5 ? 4 : data.data.maxPage - filter.page > 3 ? filter.page - 1 : data.data.maxPage - 5;
                    arr[4] = filter.page < 5 ? 5 : data.data.maxPage - filter.page > 3 ? filter.page : data.data.maxPage - 4;
                    arr[5] = filter.page < 5 ? 6 : data.data.maxPage - filter.page > 3 ? filter.page + 1 : data.data.maxPage - 3;
                    arr[6] = filter.page < 5 ? 7 : data.data.maxPage - filter.page > 3 ? filter.page + 2 : data.data.maxPage - 2;
                    arr[7] = data.data.maxPage - filter.page > 3 ? "..." : data.data.maxPage - 1;
                    arr[8] = data.data.maxPage;
                } else {
                    for (let i = 0; i < data.data.maxPage; i++) {
                        arr.push(i + 1);
                    }
                }
                setPageList(arr);
                setCheckAllFlag(false);
                setPreventChange(false);
                dispatch(setShowLoadingAction(false));
            } catch (e) {
                if (e.response?.status === 401) {
                    dispatch(clearUserAction());
                    history.replace("/login");
                }
                dispatch(setShowLoadingAction(false));
                dispatch(setErrorAction(e));
            }
        }

        if (!showError) {
            loadItems();
        }

    }, [language, filter, reloadList]);


    const updateUser = async (user: UserShort) => {
        try {
            await trainerApi.updateTrainerUser(trainer.id as number, user.id as number, user);
        } catch (e) {
            if (e.response?.status === 401) {
                dispatch(clearUserAction());
                history.replace("/login");
            }
            dispatch(setErrorAction(e));
            throw e;
        }
    }

    const deleteUser = async (id: number) => {
        try {
            await trainerApi.deleteTrainerUser(trainer.id as number, id);
        } catch (e) {
            if (e.response?.status === 401) {
                dispatch(clearUserAction());
                history.replace("/login");
            }
            dispatch(setErrorAction(e));
            throw e;
        }
    }


    const unlockResultForSelectedUsers = async () => {
        const newUserList = [...userList];
        for (let i = 0; i < newUserList.length; i++) {
            try {
                if (checkList[i]) {
                    await toggleShowResultPerUser(newUserList[i], "true")
                    checkList[i] = false;
                }
            } catch (e) {
                console.error("Could Not Set for User: ", newUserList[i].id);
                break;
            }
        }

        dispatch(setReloadList(!reloadList));
    }

    const toggleShowResultPerUser = async (user: UserShort, overwrite?: string) => {
        if (overwrite === "true") {
            user.showResult = true;
        } else if (overwrite === "false") {
            user.showResult = false;
        } else {
            user.showResult = !user.showResult;
        }
        try {
            await updateUser(user);
        } catch (e) {
            throw new Error(e);
        }
    }


    const saveGroupUpdate = (key: number) => {
        const newShowGroupEditList = {...showGroupEditList}
        newShowGroupEditList[key] = false;
        setShowGroupEditList(newShowGroupEditList);
        updateUser(userList[key]).then().catch(e => console.log(e));
    }


    const setGroupForSelectedUsers = async (group: string) => {
        for (let i = 0; i < userList.length; i++) {
            try {
                if (checkList[i]) {
                    userList[i].group = group;
                    await updateUser(userList[i]).then().catch(e => console.log(e));
                    checkList[i] = false;
                }
            } catch (e) {
                console.error("Could Not Set for User: ", userList[i].id);
                break;
            }
        }
        dispatch(setReloadList(!reloadList));
    }


    const deleteItem = (id: number) => {
        dispatch(setShowLoadingAction(true));
        deleteUser(id).then(() => {
            dispatch(setShowLoadingAction(false));
            dispatch(setReloadList(!reloadList));
        }).catch(e => {
            console.log(e);
            dispatch(setShowLoadingAction(false));
        })
    }

 
    const deleteSelectedUsers = async () => {
        dispatch(setShowLoadingAction(true));
        for (let i = 0; i < userList.length; i++) {
            try {
                if (checkList[i]) {
                    await deleteUser(userList[i].id as number);
                    checkList[i] = false;
                }
            } catch (e) {
                console.error("Could Not Set for User: ", userList[i].id);
                break;
            }
        }
        dispatch(setShowLoadingAction(false));
        dispatch(setReloadList(!reloadList));
    }


    const selectAll = () => {
        setCheckAllFlag(true);

        const tmp = [...checkList];
        for (let i = 0; i < checkList.length; i++) {
            tmp[i] = !checkedAll;
        }
        setCheckList(tmp);
        setCheckedAll(!checkedAll);
        setCheckAllFlag(false);
    }

    const checkItem = (e: any, i: number) => {
        const tmp = [...checkList];
        tmp[i] = e.detail.checked;
        setCheckList(tmp);
        checkAllCheckBoxesEqual();
        checkIndeterminate(tmp);
    }

    const checkAllCheckBoxesEqual = () => {
        let allTrue = checkList.every(function (e) {
            return e;
        });

        let allFalse = checkList.every(function (e) {
            return !e;
        });

        if (allTrue) {
            setCheckedAll(true);
        }

        if (allFalse) {
            setCheckedAll(false);
        }
    }

    const checkIndeterminate = (arr: any) => {
        let allTrue = arr.every(function (e: boolean) {
            return e;
        });

        let allFalse = arr.every(function (e: boolean) {
            return !e;
        });
        setIsIndeterminate(!allTrue && !allFalse);
    }

    return (

        <div>
            <IonRow>
                <IonCol>
                    <h2>{t("PARTICIPANTS.Header")}</h2>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <ParticipantsSearchBar unlockResultForSelectedUsers={unlockResultForSelectedUsers} setGroupForSelectedUsers={setGroupForSelectedUsers} deleteSelectedUsers={deleteSelectedUsers}/>
                </IonCol>
            </IonRow>
            <IonRow className={style.listHeader}>
                <IonCol className={style.iconCol}>
                    <IonCheckbox checked={checkedAll} onIonChange={(e) => {
                        if (!preventChange) {
                            selectAll()
                        }
                    }} indeterminate={isIndeterminate}/>
                </IonCol>
                <IonCol className={style.iconCol}>
                    <p>#</p>
                </IonCol>
                <IonCol>
                    <p onClick={() => {
                        dispatch(setFilter({...filter, ordering: "createdAt", orderingDirection: filter.orderingDirection === 'desc' ? 'asc' : 'desc'}))
                    }}>{t("PARTICIPANTSLIST.CreatedAt")}
                        {filter.ordering === 'createdAt' && filter.orderingDirection === "desc" &&
                        <IonIcon icon={chevronDown}/>
                        }
                        {filter.ordering === 'createdAt' && filter.orderingDirection === "asc" &&
                        <IonIcon icon={chevronUp}/>
                        }
                    </p>
                </IonCol>
                <IonCol>
                    <p onClick={() => {
                        dispatch(setFilter({...filter, ordering: "code", orderingDirection: filter.orderingDirection === 'desc' ? 'asc' : 'desc'}))
                    }}>{t("PARTICIPANTSLIST.Code")}
                        {filter.ordering === 'code' && filter.orderingDirection === "desc" &&
                        <IonIcon icon={chevronDown}/>
                        }
                        {filter.ordering === 'code' && filter.orderingDirection === "asc" &&
                        <IonIcon icon={chevronUp}/>
                        }</p>
                </IonCol>
                <IonCol>
                    <p onClick={() => {
                        dispatch(setFilter({...filter, ordering: "name", orderingDirection: filter.orderingDirection === 'desc' ? 'asc' : 'desc'}))
                    }}>{t("PARTICIPANTSLIST.Name")}
                        {filter.ordering === 'name' && filter.orderingDirection === "desc" &&
                        <IonIcon icon={chevronDown}/>
                        }
                        {filter.ordering === 'name' && filter.orderingDirection === "asc" &&
                        <IonIcon icon={chevronUp}/>
                        }</p>
                </IonCol>
                <IonCol>
                    <p onClick={() => {
                        dispatch(setFilter({...filter, ordering: "email", orderingDirection: filter.orderingDirection === 'desc' ? 'asc' : 'desc'}))
                    }}>{t("PARTICIPANTSLIST.Email")}
                        {filter.ordering === 'email' && filter.orderingDirection === "desc" &&
                        <IonIcon icon={chevronDown}/>
                        }
                        {filter.ordering === 'email' && filter.orderingDirection === "asc" &&
                        <IonIcon icon={chevronUp}/>
                        }</p>
                </IonCol>
                <IonCol>
                    <p onClick={() => {
                        dispatch(setFilter({...filter, ordering: "group", orderingDirection: filter.orderingDirection === 'desc' ? 'asc' : 'desc'}))
                    }}>{t("PARTICIPANTSLIST.Group")}
                        {filter.ordering === 'group' && filter.orderingDirection === "desc" &&
                        <IonIcon icon={chevronDown}/>
                        }
                        {filter.ordering === 'group' && filter.orderingDirection === "asc" &&
                        <IonIcon icon={chevronUp}/>
                        }</p>
                </IonCol>
                <IonCol>
                    <p onClick={() => {
                        dispatch(setFilter({...filter, ordering: "state", orderingDirection: filter.orderingDirection === 'desc' ? 'asc' : 'desc'}))
                    }}>{t("PARTICIPANTSLIST.State")}
                        {filter.ordering === 'state' && filter.orderingDirection === "desc" &&
                        <IonIcon icon={chevronDown}/>
                        }
                        {filter.ordering === 'state' && filter.orderingDirection === "asc" &&
                        <IonIcon icon={chevronUp}/>
                        }</p>
                </IonCol>
                <IonCol>
                    <p onClick={() => {
                        dispatch(setFilter({...filter, ordering: "personality", orderingDirection: filter.orderingDirection === 'desc' ? 'asc' : 'desc'}))
                    }}>{t("PARTICIPANTSLIST.Personality")}
                        {filter.ordering === 'personality' && filter.orderingDirection === "desc" &&
                        <IonIcon icon={chevronDown}/>
                        }
                        {filter.ordering === 'personality' && filter.orderingDirection === "asc" &&
                        <IonIcon icon={chevronUp}/>
                        }</p>
                </IonCol>
                <IonCol>
                    <p className={style.noClick}>{t("PARTICIPANTSLIST.ShowResult")}</p>
                </IonCol>
                <IonCol>
                    <p onClick={() => {
                        dispatch(setFilter({...filter, ordering: "info", orderingDirection: filter.orderingDirection === 'desc' ? 'asc' : 'desc'}))
                    }}>{t("PARTICIPANTSLIST.Info")}
                        {filter.ordering === 'info' && filter.orderingDirection === "desc" &&
                        <IonIcon icon={chevronDown}/>
                        }
                        {filter.ordering === 'info' && filter.orderingDirection === "asc" &&
                        <IonIcon icon={chevronUp}/>
                        }</p>
                </IonCol>
                <IonCol>
                    <p>{t("PARTICIPANTSLIST.Module")}</p>
                </IonCol>
                <IonCol className={classNames(style.lastHeader, style.iconCol)}>
                </IonCol>
            </IonRow>
            {userList.map((item, key) => {
                return (
                    <IonRow key={key} className={classNames(style.listRow, {[style.even_row]: key % 2 === 1})}>
                        <IonCol className={style.iconCol}>
                            <IonCheckbox checked={checkList[key]} onIonChange={(e) => {
                                if (!checkAllFlag) {
                                    checkItem(e, key);
                                }
                            }}/>
                        </IonCol>
                        <IonCol className={style.iconCol}>
                            <p>{item.memberNumber}</p>
                        </IonCol>
                        <IonCol>
                            <p>{moment(item.createdAt).format("DD.MM.YYYY")}</p>
                            <p>{moment(item.createdAt).format("HH:mm")}</p>
                        </IonCol>
                        <IonCol>
                            <p>{item.code}</p>
                        </IonCol>
                        <IonCol>
                            <p>{item.surname}, {item.name}</p>

                            <IonIcon className={style.editIcon} icon={createOutline} mode={"ios"} onClick={() => {
                                const newShowNameEditList = [...showNameEditList];
                                newShowNameEditList[key] = true;
                                setShowNameEditList(newShowNameEditList);
                            }
                            }/>

                            {showNameEditList[key] &&
                            <NameChanger
                                showModal={showNameEditList[key]}
                                setShowModal={(value: boolean) => {
                                    const newShowNameEditList = [...showNameEditList];
                                    newShowNameEditList[key] = value;
                                    setShowNameEditList(newShowNameEditList);
                                }}
                                user={userList[key]}
                                updateUser={updateUser}/>}
                        </IonCol>
                        <IonCol>
                            {item.email}
                        </IonCol>
                        <IonCol>
                            {!showGroupEditList[key] &&
                            <p>{item.group}
                                <IonIcon className={style.editIcon} icon={createOutline} mode={"ios"} onClick={() => {
                                    const newShowGroupEditList = {...showGroupEditList}
                                    newShowGroupEditList[key] = true;
                                    setShowGroupEditList(newShowGroupEditList);
                                    setTimeout(() => {
                                        editGroupInputs.current[key].setFocus();
                                    }, 10);
                                }
                                }/>
                            </p>
                            }

                            {showGroupEditList[key] &&
                            <IonInput
                                enterkeyhint={"enter"}
                                type={"text"}
                                value={item.group}
                                ref={el => editGroupInputs.current[key] = el}
                                onIonChange={(e) => {
                                    userList[key].group = e.detail.value as string;
                                }}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        saveGroupUpdate(key)
                                    }
                                }}
                                onBlur={() => {
                                    saveGroupUpdate(key);
                                }}
                            />}
                        </IonCol>
                        <IonCol>
                            {item.state === "registered" && <p>{t("PARTICIPANTSLIST.Registered")}</p>}
                            {item.state === "unregistered" && <p>{t("PARTICIPANTSLIST.Unregistered")}</p>}
                            {item.state === "surveydone" && <p>{t("PARTICIPANTSLIST.SurveyDone")}</p>}
                        </IonCol>
                        <IonCol>
                            <p>{item.personalityName}
                                <IonIcon className={style.editIcon} icon={createOutline} mode={"ios"} onClick={() => {
                                    const newShowValuesEditList = [...showValuesEditList];
                                    newShowValuesEditList[key] = true;
                                    setShowValuesEditList(newShowValuesEditList);
                                }
                                }/>
                            </p>
                            {showValuesEditList[key] &&
                            <EvaluationChanger
                                showModal={showValuesEditList[key]}
                                setShowModal={(value: boolean) => {
                                    const newShowValuesEditList = [...showValuesEditList];
                                    newShowValuesEditList[key] = value;
                                    setShowValuesEditList(newShowValuesEditList);
                                }}
                                user={userList[key]}
                                updateUser={updateUser}/>}
                        </IonCol>

                        <IonCol>
                            {item.showResult && <p>{t("GENERAL.Yes")}</p>}
                            {!item.showResult && <p>{t("GENERAL.No")}</p>}
                            <IonToggle
                                checked={item.showResult}
                                onClick={async () => {
                                    toggleShowResultPerUser(item).then().catch(e => console.log(e));
                                }}/>
                        </IonCol>
                        <IonCol>
                            <p>{item.info}</p>
                        </IonCol>
                        <IonCol>
                            <p>
                                <ul className={style.ul_format}>{item.modules?.filter(id=>modules?.find(m => m.id == id)).map((moduleId, key) => {
                                return (
                                    <li key={key} dangerouslySetInnerHTML={{__html: modules?.find(m => m.id == moduleId)?.name || ""}}></li>
                                )
                                })}</ul>
                            </p>

                            <IonIcon className={style.editIcon} icon={createOutline} mode={"ios"} onClick={() => {
                                const newShowModulesEditList = [...showModulesEditList];
                                newShowModulesEditList[key] = true;
                                setShowModulesEditList(newShowModulesEditList);
                            }
                            }/>

                            {showModulesEditList[key] &&
                            <ModuleChanger
                                showModal={showModulesEditList[key]}
                                setShowModal={(value: boolean) => {
                                    const newShowModulesEditList = [...showModulesEditList];
                                    newShowModulesEditList[key] = value;
                                    setShowModulesEditList(newShowModulesEditList);
                                }}
                                user={userList[key]}
                                allowedModules={modules}
                                updateUser={updateUser}/>}
                        </IonCol>
                        <IonCol className={classNames(style.lastHeader, style.iconCol)}>
                            {item.state === "unregistered" && <IonIcon icon={trashOutline} color={"primary"} className={style.pointer} onClick={() => {
                                present({
                                    header: t("PARTICIPANTSLIST.DeleteHeader"),
                                    message: t("PARTICIPANTSLIST.DeleteMessage"),
                                    buttons: [
                                        t("GENERAL.Cancel"),
                                        {
                                            text: "Ok", handler: () => {
                                                deleteItem(item.id as number);
                                            }
                                        }
                                    ]
                                })
                            }}/>}
                        </IonCol>
                    </IonRow>
                )
            })}
            <IonRow>
                <IonCol className={style.itemsOnPage}>
                    <IonSelect value={filter.itemsOnPage} onIonChange={(e) => {
                        const filterNew = {...filter};
                        filterNew.itemsOnPage = e.detail.value;
                        dispatch(setFilter(filterNew));
                    }}>
                        <IonSelectOption value={25}>25</IonSelectOption>
                        <IonSelectOption value={50}>50</IonSelectOption>
                        <IonSelectOption value={100}>100</IonSelectOption>
                        <IonSelectOption value={500}>500</IonSelectOption>
                    </IonSelect>
                </IonCol>
                <IonCol className={style.pagination}>
                    <IonIcon icon={chevronBack} className={classNames({[style.pointer]: filter.page !== 1})} onClick={() => {
                        if (filter.page && filter.page !== 1) {
                            dispatch(setFilter({...filter, page: filter.page - 1}))
                        }
                    }}/> 
                    {pageList.map((item, key) => {
                        return (
                            <span key={key} className={classNames(style.pageCount, {[style.activePage]: filter.page === item})} onClick={() => {
                                if (filter.page && item !== "...") {
                                    dispatch(setFilter({...filter, page: item as number}))
                                }
                            }
                            }>{item}</span>
                        )
                    })}
                    <IonIcon icon={chevronForward} className={classNames({[style.pointer]: filter.page !== maxPage})} onClick={() => {
                        if (filter.page && filter.page !== maxPage) {
                            dispatch(setFilter({...filter, page: filter.page + 1}))
                        }
                    }}/>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default ParticipantsList;
