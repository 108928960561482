import {Storage} from "@capacitor/storage";
import {Dispatch} from "react";
import {config} from "../../index";
import {clearErrorsAction} from "../error/actions";
import {clearPrivacyAction} from "../privacy/actions";
import {clearSystemStateAction} from "../system/actions";
import {AuthState, CLEAR_USER, SET_AUTH_STATE, SET_USER} from "./types";


export const setUserAction = (user: AuthState["user"]) => {
    return {
        type: SET_USER,
        payload: user
    };
};

export const setAuthAction = (user: AuthState["user"]) => {
    config.accessToken = user.token;
    return {
        type: SET_AUTH_STATE,
        payload: {token: user.token, isAuthenticated: true}
    }
};


export const clearUserAction = () => (dispatch: Dispatch<any>) => {
    Storage.clear();
    dispatch({type: CLEAR_USER})
    dispatch(clearErrorsAction());
    dispatch(clearPrivacyAction());
    dispatch(clearSystemStateAction());
};
