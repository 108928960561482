import {CLEAR_PRIVACY, PrivacyState, SET_PRIVACY_ACCEPTED, SET_PRIVACY_VERSION, SET_SHOW_PRIVACY} from "./types";

export const setPrivacyVersionAction = (value: PrivacyState["privacyVersion"]) => {
    return {
        type: SET_PRIVACY_VERSION,
        payload: value
    }
};

export const setPrivacyAcceptedAction = (value: PrivacyState["privacyAccepted"]) => {
    return {
        type: SET_PRIVACY_ACCEPTED,
        payload: value
    }
};

export const setShowPrivacyAction = (value: PrivacyState["showPrivacy"]) => {
    return {
        type: SET_SHOW_PRIVACY,
        payload: value
    }
};

export const clearPrivacyAction = () => {
    return {
        type: CLEAR_PRIVACY
    }
};
