import {IonButton, IonCol, IonGrid, IonItem, IonLabel, IonList, IonModal, IonRange, IonRow} from "@ionic/react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {setReloadList} from "../../store/data/actions";
import style from './EvaluationChanger.module.scss';

const EvaluationChanger: React.FC<any> = (props) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [red, setRed] = useState(props.user.typeRed ? props.user.typeRed : 4);
    const [green, setGreen] = useState(props.user.typeGreen ? props.user.typeGreen : 4);
    const [blue, setBlue] = useState(props.user.typeBlue ? props.user.typeBlue : 4);
    const reloadList = useSelector<AppState, boolean>(state => state.data.reloadList);


    const calculateValues = (value: number, color: string) => {
        switch (color) {
            case "red":
                setRed(value);
                break;
            case "green":
                setGreen(value);
                break;
            case "blue":
                setBlue(value);
                break;
        }
    };

    const saveEvaluation = () => {
        const newUser = {...props.user};
        newUser.typeRed = red;
        newUser.typeBlue = blue;
        newUser.typeGreen = green;
        props.updateUser(newUser).then().catch((e: any) => console.log(e));
    }

    const checkEvaluationValid = () => {
        return red + blue + green !== 48;
    }

    return (
        <IonModal isOpen={props.showModal} backdropDismiss={false} showBackdrop={true} cssClass={"evaluationModal"} onDidDismiss={() => {
            dispatch(setReloadList(!reloadList));
        }}>
            <IonGrid className={style.evaluationChangerGrid}>
                <IonRow>
                    <IonCol>
                        <h5>{t("EVALUATION.Header")}</h5>
                    </IonCol>
                </IonRow>
                <IonList>
                    <IonRow>
                        <IonCol className={style.range_col}>
                            <IonItem className={style.range_item} lines={"none"}>
                                <IonLabel className={style.label} position={"stacked"}>{t("EVALUATION.Red")} <span className={style.red}>{red}</span></IonLabel>
                                <IonRange
                                    color="diamondRed"
                                    className={style.range}
                                    mode={"ios"}
                                    pin
                                    value={red}
                                    min={3}
                                    max={(48 - green - blue) > 3 ? (48 - green - blue) > 35 ? 35 : (48 - green - blue) : 3}
                                    step={1}
                                    onIonChange={event => {
                                        calculateValues(event.detail.value as number, "red");
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className={style.range_col}>
                            <IonItem className={style.range_item} lines={"none"}>
                                <IonLabel className={style.label} position={"stacked"}>{t("EVALUATION.Green")} <span className={style.green}>{green}</span></IonLabel>
                                <IonRange
                                    color="diamondGreen"
                                    className={style.range}
                                    mode={"ios"}
                                    pin
                                    min={3}
                                    max={(48 - red - blue) > 3 ? (48 - red - blue) > 35 ? 35 : (48 - red - blue) : 3}
                                    step={1}
                                    value={green}
                                    onIonChange={event => {
                                        calculateValues(event.detail.value as number, "green");
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className={style.range_col}>
                            <IonItem className={style.range_item} lines={"none"}>
                                <IonLabel className={style.label} position={"stacked"}>{t("EVALUATION.Blue")} <span className={style.blue}>{blue}</span></IonLabel>
                                <IonRange
                                    color="diamondBlue"
                                    className={style.range}
                                    mode={"ios"}
                                    pin
                                    min={3}
                                    max={(48 - red - green) > 3 ? (48 - red - green) > 35 ? 35 : (48 - red - green) : 3}
                                    step={1}
                                    value={blue}
                                    onIonChange={event => {
                                        calculateValues(event.detail.value as number, "blue");
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonList>
                <IonRow>
                    <IonCol size={"6"}></IonCol>
                    <IonCol className={style.saveButtonCol}>
                        <IonButton disabled={checkEvaluationValid()} onClick={() => {
                            saveEvaluation();
                            props.setShowModal(false);
                        }}>{t("GENERAL.Save")}</IonButton>
                    </IonCol>
                    <IonCol className={style.saveButtonCol}>
                        <IonButton onClick={() => {
                            props.setShowModal(false);
                        }}>{t("GENERAL.Cancel")}</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonModal>
    )
}

export default EvaluationChanger;
