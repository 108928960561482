import {Filter} from "../../Api";

export interface DataState {
    filter: Filter,
    reloadList: boolean
}

export const CLEAR_DATA = "CLEAR_DATA";
export const SET_RELOAD_LIST = "SET_RELOAD_LIST";
export const SET_FILTER = "SET_FILTER";


export interface ClearData {
    type: typeof CLEAR_DATA;
}


export interface SetFilter {
    type: typeof SET_FILTER,
    payload: DataState["filter"];
}


export interface SetReloadList {
    type: typeof SET_RELOAD_LIST,
    payload: DataState["reloadList"];
}

export type ErrorActionsType = ClearData | SetFilter | SetReloadList;
