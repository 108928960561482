import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {Configuration, DataApi, MiscApi, TrainerApi, UserApi} from "./Api";
import App from './App';
import './i18n';
import configureStore, {saveState} from "./store";


export const config = new Configuration();
const env = "live";
//const env = "local";

const paths = {
    live: "https://api.mindcodex.de:4040",
    dev: "https://test.seminare-beratung.de:4040",
    local: "https://api.mindcodex.de:4040"//"http://localhost:4040"
}
config.basePath = paths[env];
config.baseOptions = ['get', 'post', 'put', 'delete', 'options'];
config.accessToken = "";
export const userApi = new UserApi(config);
export const miscApi = new MiscApi(config);
export const dataApi = new DataApi(config);
export const trainerApi = new TrainerApi(config)


configureStore().then(persistendStore => {
    const store = persistendStore;

    store.subscribe(() => {
        saveState(store.getState());
    });

    ReactDOM.render(
        <Provider store={store}>
            <Suspense fallback="...loading"><App/>
            </Suspense>
        </Provider>
        , document.getElementById('root'));

});
