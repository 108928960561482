export interface SystemState {
    language: string;
    showLoading: boolean;
    languageSetByUser: boolean;
}

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SET_SHOW_LOADING = "SET_SHOW_LOADING";
export const CLEAR_SYSTEM = "CLEAR_SYSTEM";
export const SET_LANGUAGESETBYUSER = "SET_LANGUAGESETBYUSER"


interface ChangeLanguageAction {
    type: typeof CHANGE_LANGUAGE;
    payload: SystemState["language"];
}

interface ToggleShowLoadingAction {
    type: typeof SET_SHOW_LOADING;
    payload: SystemState["showLoading"];
}

interface ClearSystem {
    type: typeof CLEAR_SYSTEM;
}

interface SetLanguageSetByUser {
    type: typeof SET_LANGUAGESETBYUSER;
    payload: SystemState["languageSetByUser"]
}

export type SystemActionTypes = ChangeLanguageAction | ToggleShowLoadingAction | ClearSystem | SetLanguageSetByUser;
