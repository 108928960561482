import {CHANGE_LANGUAGE, CLEAR_SYSTEM, SET_LANGUAGESETBYUSER, SET_SHOW_LOADING, SystemState} from "./types";

export const setLanguageAction = (language: SystemState["language"]) => {
    return {
        type: CHANGE_LANGUAGE,
        payload: language
    };
};


export const setShowLoadingAction = (value: SystemState["showLoading"]) => {
    return {
        type: SET_SHOW_LOADING,
        payload: value
    };
};

export const clearSystemStateAction = () => {
    return {
        type: CLEAR_SYSTEM
    }
}

export const setLanguageSetByUser = (value: SystemState["languageSetByUser"]) => {
    return {
        type: SET_LANGUAGESETBYUSER,
        payload: value
    };
}

