import {CLEAR_ERRORS, ErrorActionsType, ErrorState, SET_ERRORS} from "./types";

const INITIAL_STATE: ErrorState = {
    message: '',
    title: '',
    code: 0,
    isOpen: false
};

export function errorReducer(state = INITIAL_STATE, action: ErrorActionsType): ErrorState {
    switch (action.type) {
        case SET_ERRORS:
            return {
                message: action.payload.message,
                title: action.payload.title,
                code: action.payload.code,
                isOpen: true
            };
        case CLEAR_ERRORS:
            return INITIAL_STATE;
        default:
            return state;
    }
}
