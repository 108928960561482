import {IonAlert, IonApp, IonLoading, IonRouterOutlet} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import i18n from "i18next";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, Route} from 'react-router-dom';
import Home from './pages/Home/Home';
import Login from "./pages/Login/Login";
import {AppState} from "./store";
import {AuthState} from "./store/auth/types";
import {clearErrorsAction} from "./store/error/actions";
import {ErrorState} from "./store/error/types";

/* Theme variables */
import './theme/variables.css';


const App: React.FC = () => {
    const dispatch = useDispatch();
    const error = useSelector<AppState, ErrorState>(state => state.error);
    const showLoading = useSelector<AppState, boolean>(state => state.system.showLoading);
    const {t} = useTranslation();
    const language = useSelector<AppState, string>(state => state.system.language);
    const isAuth = useSelector<AppState, AuthState["isAuthenticated"]>(state => state.auth.isAuthenticated);

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
        }
    }, [])

    const errorAlert = () => {
        return (
            <IonAlert
                isOpen={error.isOpen}
                backdropDismiss={false}
                header={error.title}
                message={error.message}
                buttons={[
                    {
                        text: 'Ok',
                        handler: async () => {
                            dispatch(clearErrorsAction());
                        }
                    }
                ]}
            />
        );
    };


    const loadingIndicator = () => {
        return (
            <IonLoading
                isOpen={showLoading}
                message={t("GENERAL.LoadingMessage")}
                translucent
            />
        )
    };

    return (
        <IonApp>
            <IonReactRouter>
                <IonRouterOutlet>
                    <Route path='/login' component={Login} exact/>
                    <Route path='/home' render={() => {
                        return isAuth ? <Home/> : <Redirect to='/login'/>
                    }}/>
                    <Route path='/' render={() => <Redirect to='/home'/>} exact/>
                    <Route render={() => <Redirect to='/home'/>}/>
                </IonRouterOutlet>
            </IonReactRouter>
            {errorAlert()}
            {loadingIndicator()}
        </IonApp>);
};

export default App;
