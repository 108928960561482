import {IonButton, IonCol, IonGrid, IonItem, IonLabel, IonList, IonModal, IonRange, IonRow, IonCheckbox, IonInput} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {setReloadList} from "../../store/data/actions";
import style from './NameChanger.module.scss';

const NameChanger: React.FC<any> = (props) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
//    const [modules, setModules] = useState(props.user.modules);
    const reloadList = useSelector<AppState, boolean>(state => state.data.reloadList);
        const [firtstName, setFristName] = useState(props.user.name);
        const [lastName, setLastName] = useState(props.user.surname);
 //   const [moduleList, setModuleList] = useState<Array<Number>>(props.allowedModules.map((module: { id: any; }) => module.id));

    const saveName = () => {
        
        const newUser = {...props.user};
        newUser.name = firtstName;
        newUser.surname = lastName;
        props.updateUser(newUser).then((_: any) => window.location.reload()).catch((e: any) => console.log(e)); 

        
    }

    return (
        <IonModal isOpen={props.showModal} backdropDismiss={false} showBackdrop={true} cssClass={"evaluationModal"} onDidDismiss={() => {
            dispatch(setReloadList(!reloadList));
        }}>
            <IonGrid className={style.moduleChangerGrid}>
                <IonRow>
                    <IonCol>
                        <h5>Name</h5>
                    </IonCol>
                </IonRow>
          
                    <IonRow>

                        <IonCol>
                                <IonLabel>{t("GENERAL.Name")}</IonLabel>
                                <IonInput clearInput type={"text"} value={firtstName} onIonChange={(e) => {
                                    let name = e.detail.value as string;
                                    setFristName(name);
                                }}/>
                            </IonCol>
                            <IonCol>
                                <IonLabel>{t("GENERAL.Surname")}</IonLabel>
                                <IonInput clearInput type={"text"} value={lastName} onIonChange={(e) => {
                                    let surname = e.detail.value as string;
                                    setLastName(surname);
                                }}/>
                            </IonCol>

                    </IonRow>

                    <IonRow><IonCol></IonCol></IonRow>
 
                <IonRow>

                    <IonCol className={style.saveButtonCol}>
                        <IonButton onClick={() => {
                            saveName();
                            props.setShowModal(false);
                        }}>{t("GENERAL.Save")}</IonButton>

                        <IonButton onClick={() => {
                            props.setShowModal(false);
                        }}>{t("GENERAL.Cancel")}</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonModal>
    )
}

export default NameChanger;
