import {IonButton, IonCol, IonGrid, IonItem, IonLabel, IonList, IonModal, IonRange, IonRow, IonCheckbox} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {setReloadList} from "../../store/data/actions";
import style from './ModuleChanger.module.scss';

const ModuleChanger: React.FC<any> = (props) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [modules, setModules] = useState<Array<Number>>(props.user.modules);
    const reloadList = useSelector<AppState, boolean>(state => state.data.reloadList);
    //const [moduleList, setModuleList] = useState<Array<Number>>(props.allowedModules.map((module: { id: any; }) => module.id));

    const saveModules = () => {
        
        const newUser = {...props.user};
        newUser.modules = modules;
        props.updateUser(newUser).then().catch((e: any) => console.log(e));
        
    }

    useEffect(() => {
        console.log(props.allowedModules); 
        console.log(modules);

    }, []);

    const toggleModule = (e: any, id: number) => {
        let tmp = [...modules];
        
        if (tmp.includes(id))
            tmp = modules.filter(mid => mid != id)
        else
            tmp.push(id);

        setModules(tmp);
    }

    return (
        <IonModal isOpen={props.showModal} backdropDismiss={false} showBackdrop={true} cssClass={"evaluationModal"} onDidDismiss={() => {
            dispatch(setReloadList(!reloadList));
        }}>
            <IonGrid className={style.moduleChangerGrid}>
                <IonRow>
                    <IonCol>
                        <h5>{t("MODULE.Header")}</h5>
                    </IonCol>
                </IonRow>
          
                    <IonRow>

                        <IonCol>
                            {props.allowedModules?.map((module: any, key:any) => {
                                return (
                                    <div key={key}>
                                        <IonCheckbox checked={modules.includes(module.id)} onIonChange={(e) => {
                                            
                                            toggleModule(e, module.id);
                                        
                                        }}/>
                                        
                                        <span key={key} dangerouslySetInnerHTML={{__html: "<nobr>&nbsp;&nbsp;" + module.name + "</nobr>" || ""}}></span>
                                    </div>
                                )
                            })}
                        </IonCol>

                    </IonRow>
 
                <IonRow>

                    <IonCol className={style.saveButtonCol}>
                        <IonButton onClick={() => {
                            saveModules();
                            props.setShowModal(false);
                        }}>{t("GENERAL.Save")}</IonButton>

                        <IonButton onClick={() => {
                            props.setShowModal(false);
                        }}>{t("GENERAL.Cancel")}</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonModal>
    )
}

export default ModuleChanger;
