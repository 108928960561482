import endOfDay from 'date-fns/endOfDay'
import sub from "date-fns/sub";
import {CLEAR_DATA, DataState, ErrorActionsType, SET_FILTER, SET_RELOAD_LIST} from "./types";

const INITIAL_STATE: DataState = {
    filter: {
        startDate: sub(new Date(), {years: 2}).toISOString(),
        endDate: endOfDay(new Date()).toISOString(),
        ordering: 'createdAt',
        orderingDirection: 'desc',
        state: '',
        page: 1,
        itemsOnPage: 25,
    },
    reloadList: false
};

export function dataReducer(state = INITIAL_STATE, action: ErrorActionsType): DataState {
    switch (action.type) {
        case SET_FILTER:
            return {...state, filter: action.payload};
        case SET_RELOAD_LIST:
            return {...state, reloadList: action.payload};
        case CLEAR_DATA:
            return INITIAL_STATE;
        default:
            return state;
    }
}
