import {IonButton, IonButtons, IonChip, IonCol, IonGrid, IonHeader, IonIcon, IonInput, IonLabel, IonModal, IonRadio, IonRadioGroup, IonRow, IonTitle, IonToolbar} from "@ionic/react";
import classNames from "classnames";
import {addOutline, closeOutline, trashOutline} from "ionicons/icons";
import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from 'react-dropzone'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {ModuleShort, TrainerUserList, User, UserShort} from "../../Api";
import {dataApi, trainerApi} from "../../index";
import {AppState} from "../../store";
import {clearUserAction} from "../../store/auth/actions";
import {setReloadList} from "../../store/data/actions";
import {setErrorAction} from "../../store/error/actions";
import {setShowLoadingAction} from "../../store/system/actions";
import style from './CreateNewModal.module.scss';

const CreateNewModal: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const history = useHistory()
    const {t} = useTranslation();
    const user = useSelector<AppState, User>(state => state.auth.user);
    const [newUsers, setNewUsers] = useState<Array<UserShort>>([{}]);
    const [showResult, setShowResult] = useState<boolean>(false);
    const [modules, setModules] = useState<Array<ModuleShort>>();
    const language = useSelector<AppState, string>(state => state.system.language);
    const reloadList = useSelector<AppState, boolean>(state => state.data.reloadList);


    useEffect(() => {
        const loadModules = async () => {
            try {
                dispatch(setShowLoadingAction(true));
                const response = await dataApi.getModulesShort(language, user.id as number);
                setModules(response.data);
                dispatch(setShowLoadingAction(false));
            } catch (e) {
                if (e.response?.status === 401) {
                    dispatch(clearUserAction());
                    history.replace("/login");
                }
                dispatch(setShowLoadingAction(false));
                dispatch(setErrorAction(e));
            }
        }
        loadModules();
    }, [language])

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file: any) => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                const str = reader.result as String;
                const rows = str.split("\n")
                const tmpUsers = JSON.parse(JSON.stringify(newUsers));

                for (let i = 0; i < tmpUsers.length; i++) {
                    if (
                        (!tmpUsers[i].name || tmpUsers[i].name === "") &&
                        (!tmpUsers[i].surname || tmpUsers[i].surname === "") &&
                        (!tmpUsers[i].email || tmpUsers[i].email === "") &&
                        (!tmpUsers[i].group || tmpUsers[i].group === "")) {
                        tmpUsers.splice(i, 1);
                        i--;
                    }
                }

                for (let row of rows) {
                    let col = row.split(";");
                    tmpUsers.push({
                        name: col[0],
                        surname: col[1],
                        email: col[2],
                        group: col[3],
                        showResult: showResult
                    })
                }
                setNewUsers(tmpUsers);
            }

            reader.readAsText(file);
        })
    }, []);

    const {getRootProps, getInputProps} = useDropzone({onDrop});


    const createNewUsers = async () => {
        dispatch(setShowLoadingAction(true));
        try {
            const trainerList: TrainerUserList = {
                userList: []
            }

            // fill user object with data
            for (let user of newUsers) {
                user.showResult = showResult;
                user.createdAt = moment().toISOString();
                trainerList?.userList?.push(user);
            }

            const data = await trainerApi.postTrainerUserList(user.id as number, trainerList)
            dispatch(setShowLoadingAction(false));
            props.setShowModal(false);
            dispatch(setReloadList(!reloadList))

            if (data.data && data.data.message !== "") {
                alert(data.data.message);
            }

        } catch (e) {
            console.log(e);
            dispatch(setShowLoadingAction(false));
            if (e.response?.status === 401) {
                dispatch(clearUserAction());
                history.replace("/login");
            }
            dispatch(setErrorAction(e));

        }
    }

    const changeModulesForUser = (item: ModuleShort, key: number) => {
        let temp = JSON.parse(JSON.stringify(newUsers));

        if (temp[key].modules === undefined) {
            temp[key].modules = [];
        }

        let dupplicate = checkModuleIsSetForUser(item, temp[key]);

        if (dupplicate) {
            var index = temp[key].modules.indexOf(item.id);
            if (index !== -1) {
                temp[key].modules.splice(index, 1);
            }
        } else {
            temp[key].modules.push(item.id as number);
        }
        setNewUsers(temp);
    }

    const changeModulesForEachUser = (item: ModuleShort) => {
        let temp = JSON.parse(JSON.stringify(newUsers));

        let isSetForAll = checkModuleIsSetForAllUsers(item);

        if (isSetForAll) {
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].modules === undefined) {
                    temp[i].modules = [];
                }
                var index = temp[i].modules.indexOf(item.id);
                if (index !== -1) {
                    temp[i].modules.splice(index, 1);
                }
            }
        } else {
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].modules === undefined) {
                    temp[i].modules = [];
                }
                let dupplicate = checkModuleIsSetForUser(item, temp[i]);
                if (!dupplicate) {
                    temp[i].modules.push(item.id as number);
                }
            }
        }
        setNewUsers(temp);
    }

    const checkModuleIsSetForAllUsers = (item: ModuleShort): boolean => {
        let isSetForAll = true;
        for (let user of newUsers) {
            if (user.modules === undefined) {
                user.modules = [];
            }
            let isSetForUser = checkModuleIsSetForUser(item, user);
            if (!isSetForUser) {
                isSetForAll = false;
            }
        }
        return isSetForAll;
    }

    const checkModuleIsSetForUser = (item: ModuleShort, user: UserShort): boolean => {
        let isSetForUser = false;
        if (user.modules === undefined) {
            user.modules = [];
        }
        for (let module of user.modules) {
            if (module === item.id) {
                isSetForUser = true;
            }
        }
        return isSetForUser;
    }


    return (
        <IonModal cssClass={"createNewModal"} isOpen={props.showModal} onWillPresent={() => {
            setNewUsers([{}]);
        }}>
            <IonHeader>
                <IonToolbar color={"module"}>
                    <IonTitle>{t("GENERAL.Mindcodex")}</IonTitle>
                    <IonButtons slot={"end"}>
                        <IonButton onClick={() => {
                            props.setShowModal(false)
                        }}>{t("GENERAL.Cancel")}<IonIcon className={style.cancelIcon} icon={closeOutline}/></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonGrid className={style.newUserGrid}>
                <IonRow>
                    <IonCol>
                        <h5>{t("CREATEMODAL.CSVHeader")}</h5>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <div className={style.dropDiv} {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>{t("GENERAL.ImportCSV")}</p>
                        </div>
                    </IonCol>
                    <IonCol>
                        <p>{t("CREATEMODAL.CSV1")}</p>
                        <p>{t("CREATEMODAL.CSV2")}</p>
                        <p className={style.noMargin}>{t("CREATEMODAL.CSV3")}</p>
                        <p className={style.noMargin}>{t("CREATEMODAL.CSV4")}</p>
                        <p className={style.noMargin}>{t("CREATEMODAL.CSV5")}</p>
                        <IonButton download={"csvexample"} href={"https://test.mindcodex.de/assets/example.csv"}>Download</IonButton>
                    </IonCol>
                    <IonCol>
                        <IonRadioGroup value={showResult} onIonChange={(e) => {
                            setShowResult(e.detail.value)
                        }}>
                            <IonRow>
                                <IonCol className={style.showResultRadio}>
                                    <IonLabel>{t("CREATEMODAL.ShowResultDirectly")}</IonLabel>
                                    <IonRadio value={true}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className={style.showResultRadio}>
                                    <IonLabel>{t("CREATEMODAL.ShowResultManually")}</IonLabel>
                                    <IonRadio value={false}/>
                                </IonCol>
                            </IonRow>

                        </IonRadioGroup>
                    </IonCol>
                </IonRow>
                <IonRow className={style.editRow}>
                    <IonCol size={"8"}>
                        <h5>{t("CREATEMODAL.EditUsers")}</h5>
                    </IonCol>
                    <IonCol>
                        <h5>{t("CREATEMODAL.SetModuleForAllUsers")}</h5>
                        {modules && modules.map((item, i) => {
                            return (
                                <IonChip
                                    className={classNames({[style.activeChip]: checkModuleIsSetForAllUsers(item)}, style.chip)}
                                    key={i}
                                    onClick={() => {
                                        changeModulesForEachUser(item)
                                    }}><span dangerouslySetInnerHTML={{__html: item.name as string}}/></IonChip>
                            )
                        })}
                    </IonCol>
                    <IonCol className={style.createButtonCol}>
                        <IonButton onClick={() => {
                            const temp = JSON.parse(JSON.stringify(newUsers))
                            temp.push({});
                            setNewUsers(temp);
                        }}>
                            <IonIcon slot="icon-only" icon={addOutline}/>
                        </IonButton>
                        <IonButton onClick={() => {
                            createNewUsers();
                        }}>
                            {t("GENERAL.Create")}
                        </IonButton>
                    </IonCol>
                </IonRow>
                {newUsers.map((item, key) => {
                    return (
                        <IonRow key={key}>
                            <IonCol size={"2"}>
                                <IonLabel>{t("GENERAL.Name")}</IonLabel>
                                <IonInput type={"text"} value={newUsers[key].name} onIonChange={(e) => {
                                    newUsers[key].name = e.detail.value as string;
                                    setNewUsers(newUsers);
                                }}/>
                            </IonCol>
                            <IonCol size={"2"}>
                                <IonLabel>{t("GENERAL.Surname")}</IonLabel>
                                <IonInput type={"text"} value={newUsers[key].surname} onIonChange={(e) => {
                                    newUsers[key].surname = e.detail.value as string;
                                    setNewUsers(newUsers);
                                }}/>
                            </IonCol>
                            <IonCol size={"2"}>
                                <IonLabel>{t("GENERAL.Email")}</IonLabel>
                                <IonInput type={"email"} value={newUsers[key].email} onIonChange={(e) => {
                                    newUsers[key].email = e.detail.value as string;
                                    setNewUsers(newUsers);
                                }}/>
                            </IonCol>
                            <IonCol size={"2"}>
                                <IonLabel>{t("PARTICIPANTSLIST.Group")}</IonLabel>
                                <IonInput type={"text"} value={newUsers[key].group} onIonChange={(e) => {
                                    newUsers[key].group = e.detail.value as string;
                                    setNewUsers(newUsers);
                                }}/>
                            </IonCol>
                            <IonCol size={"3"}>
                                <IonLabel>{t("CREATEMODAL.Module")}</IonLabel>
                                <br/>
                                {modules && modules.map((item, i) => {
                                    return (
                                        <IonChip
                                            className={classNames({[style.activeChip]: newUsers[key]?.modules?.includes(item.id as number)}, style.chip)}
                                            key={i}
                                            onClick={() => {
                                                changeModulesForUser(item, key)

                                            }}
                                        ><span dangerouslySetInnerHTML={{__html: item.name as string}}/></IonChip>
                                    )
                                })}

                            </IonCol>
                            <IonCol className={style.rowButtons} size={"1"}>
                                {newUsers.length > 1 && <IonButton onClick={() => {
                                    const temp = JSON.parse(JSON.stringify(newUsers))
                                    temp.splice(key, 1);
                                    setNewUsers(temp);
                                }}>
                                    <IonIcon icon={trashOutline}/>
                                </IonButton>}
                            </IonCol>
                        </IonRow>
                    )
                })}
            </IonGrid>
        </IonModal>
    )
}

export default CreateNewModal;
