import {IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonTitle, IonToolbar} from '@ionic/react';
import classNames from "classnames";
import i18n from "i18next";
import {logOutOutline} from "ionicons/icons";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {User} from "../../Api";
import CreateNewModal from "../../components/CreateNewModal/CreateNewModal";
import ParticipantsList from "../../components/ParticipantsList/ParticipantsList";
import {userApi} from "../../index";
import {AppState} from "../../store";
import {clearUserAction, setUserAction} from "../../store/auth/actions";
import {setErrorAction} from "../../store/error/actions";
import {setLanguageAction, setLanguageSetByUser, setShowLoadingAction} from "../../store/system/actions";
import style from './Home.module.scss';

const Home: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector<AppState, User>(state => state.auth.user);
    const {t} = useTranslation();
    const language = useSelector<AppState, string>(state => state.system.language);
    const [showModal, setShowModal] = useState(false);
    const isAuth = useSelector<AppState, boolean>(state => state.auth.isAuthenticated)
    const showError = useSelector<AppState, boolean>(state => state.error.isOpen);

    useEffect(() => {
        const reloadUser = async () => {
            try {
                dispatch(setShowLoadingAction(true));
                const response = await userApi.getUser(user.id as number, language);
                dispatch(setUserAction(response.data));
                dispatch(setShowLoadingAction(false));
            } catch (e) {
                if (e.response?.status === 401) {
                    dispatch(clearUserAction());
                    history.replace("/login");
                }
                dispatch(setErrorAction(e));
            }
        };
        if (isAuth) {
            console.log("HIER");
            if (!showError) {
                reloadUser();
            }
        } else {
            history.replace("/login");
        }
    }, [language])


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color={"module"}>
                    <IonTitle>
                        <img className={style.logo} src={"/assets/logo/logo.svg"} alt={""}/>
                    </IonTitle>
                    <IonButtons slot={"end"}>
                        <IonButton className={classNames(style.languageButton, {[style.activeLanguage]: language === "de"})} onClick={() => {
                            dispatch(setLanguageSetByUser(true));
                            dispatch(setLanguageAction("de"))
                            i18n.changeLanguage("de");
                        }}>
                            {t("GENERAL.German")}
                        </IonButton>
                        <IonButton className={classNames(style.languageButton, {[style.activeLanguage]: language === "en"})} onClick={() => {
                            dispatch(setLanguageSetByUser(true));
                            dispatch(setLanguageAction("en"))
                            i18n.changeLanguage("en");
                        }}>
                            {t("GENERAL.English")}
                        </IonButton>
                        <IonButton onClick={() => {
                            dispatch(clearUserAction());
                            history.replace('/login');
                        }}>{t("GENERAL.Logout")} <IonIcon className={style.logoutIcon} icon={logOutOutline}/></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <CreateNewModal showModal={showModal} setShowModal={setShowModal}/>
                <IonGrid className={style.homeGrid}>
                    <IonRow>
                        <IonCol>
                            <h5>{t("HOME.Greeting")}, {user.name} {user.surname}</h5>
                            <p>{t("HOME.Greeting2")}</p>
                        </IonCol>
                        <IonCol className={style.createNewButton}>
                            <IonButton onClick={() => {
                                setShowModal(true);
                            }}>
                                {t("HOME.CreateNew")}
                            </IonButton>
                            {user.email && (user.email?.includes("pfitzmayer.de") || user.email?.includes("mindcodex.de") || user.email?.includes("seminare-beratung.de")) 
                                && <IonButton download="Export" href="https://api.mindcodex.de:4040/trainer/user/export">Export</IonButton>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {isAuth && <ParticipantsList/>}
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Home;
