import {IonButton, IonCol, IonIcon, IonInput, IonRow, IonSelect, IonSelectOption, useIonAlert, useIonViewDidEnter} from "@ionic/react";
import de from 'date-fns/locale/de';
import sub from 'date-fns/sub'
import {searchOutline} from "ionicons/icons";
import React, {useEffect, useState} from "react";
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {clearDataState, setFilter} from "../../store/data/actions";
import {DataState} from "../../store/data/types";
import style from './ParticipantsSearchBar.module.scss';

registerLocale("de", de);


const ParticipantsSearchBar: React.FC<any> = (props) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const language = useSelector<AppState, string>(state => state.system.language);
    const filter = useSelector<AppState, DataState["filter"]>(state => state.data.filter);
    const [searchInput, setSearchInput] = useState<string>("");
    const [batchSelect, setBatchSelect] = useState<number>(1);
    const [groupAllInput, setGroupAllInput] = useState<string>("");
    const [prompt] = useIonAlert();


    useIonViewDidEnter(() => {
        if (language === "de") {
            setDefaultLocale("de");
        } else {
            setDefaultLocale("en");
        }
    })

    useEffect(() => {
        dispatch(clearDataState());
    }, [dispatch])


    const storeSearch = () => {
        dispatch(setFilter({...filter, search: searchInput}));
    }

    const getDate = (key: string) => {
        let date;
        if (key === "start") {
            date = new Date(filter.startDate as string)
            if (date && isNaN(date.getTime())) {
                date = sub(new Date(), {years: 2});
            }
        } else if (key === "end") {
            date = new Date(filter.endDate as string)
            if (date && isNaN(date.getTime())) {
                date = new Date();
            }
        }
        return date;
    }

    const executeBatch = () => {
        switch (batchSelect) {
            case 1:
                props.unlockResultForSelectedUsers()
                break;
            case 2:
                props.setGroupForSelectedUsers(groupAllInput);
                break;
            case 3:
                prompt({
                    header: t("PARTICIPANTSSEARCHBAR.DeleteHeader"),
                    message: t("PARTICIPANTSSEARCHBAR.DeleteHeader"),
                    buttons: [t("GENERAL.No"), {
                        text: t("GENERAL.Yes"), handler: () => {
                            props.deleteSelectedUsers()
                        }
                    }]
                })

                break;
        }
    }


    return (
        <div>
            <IonRow className={style.searchBarRow}>
                <IonCol sizeXl={"2"} sizeMd={"4"} size={"12"}>
                    <IonInput className={style.searchInput} placeholder={t("GENERAL.Search")} value={searchInput} onIonChange={e => {
                        setSearchInput(e.detail.value as string);
                    }
                    } clearInput/>
                    <div className={style.searchButton} onClick={() => {
                        storeSearch();
                    }}>
                        <IonIcon icon={searchOutline}/>
                    </div>
                </IonCol>
                <IonCol sizeXl={"2"} sizeMd={"4"} size={"6"}>
                    <p>{t("GENERAL.From")}</p>
                    <DatePicker
                        selected={getDate("start")}
                        popperPlacement={"bottom-end"}
                        dateFormat={"dd.MM.yyyy"}
                        onChange={(e: Date) => {
                            dispatch(setFilter({...filter, startDate: e.toDateString()}));
                        }}
                    />
                </IonCol>
                <IonCol sizeXl={"2"} sizeMd={"4"} size={"6"}>
                    <p>{t("GENERAL.To")}</p>
                    <DatePicker
                        selected={getDate("end")}
                        popperPlacement={"bottom-end"}
                        dateFormat={"dd.MM.yyyy"}
                        onChange={(e: Date) => {
                            dispatch(setFilter({...filter, endDate: e.toDateString()}));
                        }}
                    />
                </IonCol>
                <IonCol sizeXl={"2"} sizeMd={"4"} size={"6"}>
                    <p>{t("GENERAL.State")}</p>
                    <IonSelect value={filter.state} onIonChange={e => {
                        dispatch(setFilter({...filter, state: e.detail.value}));
                    }
                    }>
                        <IonSelectOption value={""}>{t("PARTICIPANTSSEARCHBAR.States.All")}</IonSelectOption>
                        <IonSelectOption value={"registered"}>{t("PARTICIPANTSSEARCHBAR.States.Registered")}</IonSelectOption>
                        <IonSelectOption value={"filled"}>{t("PARTICIPANTSSEARCHBAR.States.Filled")}</IonSelectOption>
                        <IonSelectOption value={"released"}>{t("PARTICIPANTSSEARCHBAR.States.Released")}</IonSelectOption>
                    </IonSelect>
                </IonCol>
                <IonCol className={style.batchCol}>
                    <div>
                        <p>{t("PARTICIPANTSSEARCHBAR.ForAll")}</p>
                        <div className={style.flexCol}>
                            <IonSelect value={batchSelect} interface={"popover"} onIonChange={(e) => {
                                setBatchSelect(e.detail.value);
                            }}>
                                <IonSelectOption value={1}>{t("PARTICIPANTSSEARCHBAR.ShowResult")}</IonSelectOption>
                                <IonSelectOption value={2}>{t("PARTICIPANTSSEARCHBAR.ChangeGroupe")}</IonSelectOption>
                                <IonSelectOption value={3}>{t("PARTICIPANTSSEARCHBAR.DeleteAll")}</IonSelectOption>
                            </IonSelect>
                            {batchSelect === 2 && <IonInput className={style.groupAllInput} value={groupAllInput} onIonChange={e => setGroupAllInput(e.detail.value as string)} type={"text"}/>}
                        </div>
                    </div>
                    <div>
                        <IonButton className={style.batchButton} onClick={() => {
                            executeBatch()
                        }}>{t("GENERAL.Update")}</IonButton>
                    </div>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default ParticipantsSearchBar;
