export interface ErrorState {
    message: string;
    title: string | undefined;
    code: number | undefined;
    isOpen: boolean;
}

export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export interface SetErrors {
    type: typeof SET_ERRORS
    payload: ErrorState;
}

export interface ClearErrors {
    type: typeof CLEAR_ERRORS;
    payload: ErrorState;
}

export type ErrorActionsType = SetErrors | ClearErrors;
