import {CLEAR_DATA, DataState, SET_FILTER, SET_RELOAD_LIST} from "./types";


export const setFilter = (value: DataState["filter"]) => {
    return {
        type: SET_FILTER,
        payload: value
    }
}

export const setReloadList = (value: DataState["reloadList"]) => {
    return {
        type: SET_RELOAD_LIST,
        payload: value
    }
}

export const clearDataState = () => {
    return {
        type: CLEAR_DATA
    }
}
