import {IonButton, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonPage, IonRow, IonTitle, IonToolbar} from "@ionic/react";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {User} from "../../Api";
import {userApi} from "../../index";
import {AppState} from "../../store";
import {setAuthAction, setUserAction} from "../../store/auth/actions";
import {setErrorAction} from "../../store/error/actions";
import {setShowLoadingAction} from "../../store/system/actions";
import style from './Login.module.scss';

const Login: React.FC = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const language = useSelector<AppState, string>(state => state.system.language);
    const history = useHistory();
    const user = useSelector<AppState, User>(state => state.auth.user);
    const emailInput = useRef(null);
    const passwordInput = useRef(null);


    // fix for passwort managers
    useEffect(() => {
        const initInputListeners = async () => {

            // @ts-ignore
            const nativeEmailInput = await emailInput.current.getInputElement();
            // @ts-ignore
            const nativePasswordInput = await passwordInput.current.getInputElement();

            nativeEmailInput.addEventListener('change', (ev: Event) => {
                requestAnimationFrame(() => {
                    setUsername((ev.target as HTMLInputElement).value);
                });
            });

            nativePasswordInput.addEventListener('change', (ev: Event) => {
                requestAnimationFrame(() => {
                    setPassword((ev.target as HTMLInputElement).value);
                });
            });
        }

        initInputListeners();
    }, [])


    const login = async () => {
        try {
            dispatch(setShowLoadingAction(true));
            user.password = password;
            user.username = username;
            const response = await userApi.login(language, user, true);
            dispatch(setUserAction(response.data));
            dispatch(setAuthAction(response.data));
            history.replace("/home");
            dispatch(setShowLoadingAction(false));
        } catch (e) {
            dispatch(setErrorAction(e));
        }
    };

    const checkEmailValid = (): boolean => {
        const emailRegEx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return emailRegEx.test(username as string);
    };


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="headerBlue">
                    <IonTitle>
                        <img className={style.logo} src={"/assets/logo/logo.svg"} alt={""}/>
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className={style.loginContent}>
                <IonGrid className={style.loginGrid}>
                    <IonRow>
                        <IonCol className={style.logo_big}>
                            <img src={"./assets/logo/logo_no_text.svg"} alt={""}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <h1>Login</h1>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonInput ref={emailInput} type='email' autocomplete={"email"} placeholder={t('GENERAL.Email')} onIonChange={event => {
                                setUsername(event.detail.value as string);
                            }} onKeyDown={(e) => {
                                if (e.keyCode === 13 && password !== '' && checkEmailValid()) {
                                    login();
                                }
                            }}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonInput ref={passwordInput} type='password' autocomplete={"current-password"} placeholder={t('GENERAL.Password')} onIonChange={event => {
                                setPassword(event.detail.value as string);
                            }} onKeyDown={(e) => {
                                if (e.keyCode === 13 && password !== '' && checkEmailValid()) {
                                    login();
                                }
                            }}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCol>
                                <IonButton
                                    color={"white"}
                                    expand={"block"}
                                    fill={(password.length !== 0 && checkEmailValid()) ? "solid" : "outline"}
                                    onClick={async event => {
                                        login();
                                    }}>{t('LOGIN.DoLogin')}</IonButton>
                            </IonCol>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <p><a href={"#"}>{t("LOGIN.PasswordForgotten")}</a></p>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default Login;
