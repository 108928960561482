import {CHANGE_LANGUAGE, CLEAR_SYSTEM, SET_LANGUAGESETBYUSER, SET_SHOW_LOADING, SystemActionTypes, SystemState} from "./types";

const INITIAL_STATE: SystemState = {
    language: "de",
    showLoading: false,
    languageSetByUser: false
};

export const systemReducer = (state: SystemState = INITIAL_STATE, action: SystemActionTypes): SystemState => {
    switch (action.type) {
        case CHANGE_LANGUAGE: {
            return {...state, language: action.payload}
        }
        case SET_SHOW_LOADING: {
            return {...state, showLoading: action.payload}
        }
        case CLEAR_SYSTEM: {
            return INITIAL_STATE;
        }
        case SET_LANGUAGESETBYUSER: {
            return {...state, languageSetByUser: action.payload}
        }
        default:
            return state;
    }
};

