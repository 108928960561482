export interface PrivacyState {
    privacyAccepted: boolean;
    privacyVersion: number;
    showPrivacy: boolean;
}

export const SET_PRIVACY_ACCEPTED = "SET_PRIVACY_ACCEPTED";
export const SET_PRIVACY_VERSION = "SET_PRIVACY_VERSION";
export const SET_SHOW_PRIVACY = "SET_SHOW_PRIVACY";
export const CLEAR_PRIVACY = "CLEAR_PRIVACY";

export interface SetPrivacyAccepted {
    type: typeof SET_PRIVACY_ACCEPTED;
    payload: PrivacyState["privacyAccepted"];
}

export interface SetPrivacyVersion {
    type: typeof SET_PRIVACY_VERSION;
    payload: PrivacyState["privacyVersion"];
}

export interface SetShowPrivacy {
    type: typeof SET_SHOW_PRIVACY
    payload: PrivacyState["showPrivacy"];
}


export interface ClearPrivacy {
    type: typeof CLEAR_PRIVACY;

}


export type PrivacyActionTypes = SetPrivacyAccepted | ClearPrivacy | SetPrivacyVersion | SetShowPrivacy;
