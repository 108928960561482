import {User} from "../../Api";

export interface AuthState {
    isAuthenticated: boolean;
    token: string;
    user: User
}

export const SET_AUTH_STATE = "SET_AUTH_STATE";
export const CLEAR_USER = "CLEAR_USER";
export const SET_USER = "SET_USER";

export interface SetAuthState {
    type: typeof SET_AUTH_STATE;
    payload: AuthState;
}

export interface ClearUser {
    type: typeof CLEAR_USER;
}

export interface SetUser {
    type: typeof SET_USER;
    payload: AuthState["user"];
}


export type AuthActionTypes = SetAuthState | ClearUser | SetUser;
