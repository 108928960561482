import {CLEAR_PRIVACY, SET_PRIVACY_ACCEPTED, SET_PRIVACY_VERSION, SET_SHOW_PRIVACY} from "../privacy/types";
import {PrivacyActionTypes, PrivacyState} from "./types";

const INITIAL_STATE: PrivacyState = {
    privacyAccepted: false,
    privacyVersion: 0,
    showPrivacy: false
};


export const privacyReducer = (state: PrivacyState = INITIAL_STATE, action: PrivacyActionTypes): PrivacyState => {
    switch (action.type) {
        case SET_PRIVACY_ACCEPTED:
            return {...state, privacyAccepted: action.payload};
        case SET_PRIVACY_VERSION:
            return {...state, privacyVersion: action.payload};
        case SET_SHOW_PRIVACY:
            return {...state, showPrivacy: action.payload};
        case CLEAR_PRIVACY:
            return INITIAL_STATE;
        default:
            return state;
    }
};
